import { Button, Grid, Heading, Layout, Paragraph } from '@components';
import { getGlobalProps } from '@utils/get-global-props';

export function Custom404() {
	return (
		<Grid style={{ padding: '10rem' }} centerItems columns={1} gap={4}>
			<Heading tag='h1'>404 Page Not Found</Heading>
			<Paragraph>The page you requested does not exist.</Paragraph>
			<Button href='/'>Continue Shopping</Button>
		</Grid>
	);
}

export async function getStaticProps({ locale }) {
	const globalProps = await getGlobalProps({ locale });

	return {
		props: {
			...globalProps,
		},
	};
}

Custom404.getLayout = function getLayout(page, pageProps) {
	return <Layout pageProps={pageProps}>{page}</Layout>;
};

export default Custom404;
